import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegisterPage from './modules/authentication/register/RegistrationPage'
import LoginPage from './modules/authentication/login/LoginPage'
import Activate from './modules/authentication/Activate/Activate'
import PaymentPage from './modules/payingpage/PaymentPage'
import PhoneVerification from './modules/authentication/PhoneVerification/PhoneVerification'
import PrivateRoute from './components/PrivateRoutes'
import AuthSuccessPage from './modules/authentication/authSuccessPage/AuthSuccessPage';
import Dashboard from './modules/dashboard/Dashboard'
import GameInfo from './modules/gameInfoPage/GamePage'
import HomePage from './modules/homePage/HomePage'
import Profile from './modules/profile/Profile'
import CreateGame from './modules/createGame/CreateGame'
import GamePage from './modules/gamePage/GamePage'

function App() {
  return (
    <Router>
      <Routes> 
        <Route element={<PrivateRoute/>}>
              <Route path="/validate-otp" element= {<PhoneVerification/>} /> 
              <Route path="/create-game" element= {<CreateGame/>} /> 
              <Route path="/game/:game_id" element= {<GamePage/>} />
              <Route path="/edit-profile" element= {<Profile/>} /> 
              <Route path="/game-details/:game_id" element= {<GameInfo/>} />
        </Route>
        <Route>
            <Route path="/create-game" element= {<CreateGame/>} /> 
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/" element= {<HomePage/>} />
            <Route path="/register" element= {<RegisterPage/>} />
            <Route path="/user/activate/:token" element= {<Activate/>} />
            <Route path="/login" element= {<LoginPage/>} />
            <Route path="/register" element= {<RegisterPage/>} />
            <Route path="/auth-success" element={<AuthSuccessPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

