import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

// Async thunk for logging in user
export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/auth/login`, { email, password });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for activating account
export const activateAccount = createAsyncThunk(
  'auth/activate',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/auth/activate/${token}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching user profile based on access token
export const fetchUserProfile = createAsyncThunk(
  'auth/fetchProfile',
  async (token, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/auth/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  user : localStorage.getItem('user') || null,
  token: localStorage.getItem('token') || null,
  balance: localStorage.getItem('balance') || null,
  profilePic: localStorage.getItem('profilePic') || null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.balance = null;
      state.profilePic = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('balance');
      localStorage.removeItem('profilePic');
    },
    setAccessToken: (state, action) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    setAuthData: (state, action) => {
      state.user = action.payload.username;
      state.token = action.payload.token;
      state.balance = action.payload.balance;
      state.profilePic = action.payload.profile_pic;

      // Store token and user details in localStorage
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', action.payload.username);
      localStorage.setItem('balance', action.payload.balance);
      localStorage.setItem('profilePic', action.payload.profile_pic);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.username;
        state.token = action.payload.token;
        state.balance = action.payload.balance;
        state.profilePic = action.payload.profile_pic;

        // Call setAuthData to store the details
        authSlice.caseReducers.setAuthData(state, {
          payload: action.payload,
        });
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error || 'Login failed';
      })
      .addCase(activateAccount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(activateAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.username;
        state.token = action.payload.token;
        state.balance = action.payload.balance;
        state.profilePic = action.payload.profile_pic;

        // Call setAuthData to store the details
        authSlice.caseReducers.setAuthData(state, {
          payload: action.payload,
        });
      })
      .addCase(activateAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error || 'Account activation failed';
      })
      .addCase(fetchUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.username;
        state.balance = action.payload.balance;
        state.profilePic = action.payload.profile_pic;

        // Store user details in localStorage
        localStorage.setItem('user', action.payload.username);
        localStorage.setItem('balance', action.payload.balance);
        localStorage.setItem('profilePic', action.payload.profile_pic);
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.error || 'Fetching profile failed';
      });
  },
});

export const { resetError, logout, setAccessToken, setAuthData } = authSlice.actions;
export default authSlice.reducer;
