import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/Footer/Footer';
import AvailableGames from './components/AvailableGames';
import Rankings from './components/Rankings';
import { FaPlusCircle } from 'react-icons/fa';
import api from '../../api/axiosInstane';
import MobileVerification from './components/MobileVerification';

function Dashboard() {
  const [gameId, setGameId] = useState('');
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [hasMobileNumber, setHasMobileNumber] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      const fetchGames = async () => {
        try {
          const response = await api.get('/api/games/my-games');
          setGames(response.data);
        } catch (error) {
          console.error('Error fetching games:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchGames();

      const checkMobileNumber = async () => {
        try {
          const response = await api.get('/api/auth/check-mobile-number', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setHasMobileNumber(response.data.hasMobileNumber);
        } catch (error) {
          console.error('Error checking mobile number:', error);
        }
      };

      checkMobileNumber();
    }
  }, [navigate]);

  const handleJoinGame = async () => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    if (!gameId || gameId.length < 36 || !uuidRegex.test(gameId)) {
      setError('Invalid Game ID format');
      return;
    }

    try {
      navigate(`/game-details/${gameId}`);
    } catch (error) {
      setError('Game not found. Please check the Game ID and try again.');
    }
  };

  const handleCreateGame = () => {
    navigate('/create-game');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-green-900 to-green-700 text-white">
      <Header />

      <div className="p-6 max-w-7xl mx-auto">
        <h1 className="text-4xl font-extrabold mb-6 text-center">Welcome to the MLRC</h1>

        {/* Show Mobile Verification Section only if user has not added mobile number */}
        {!hasMobileNumber && <MobileVerification />}

        {/* Game Creation and Joining Section */}
        <div className="bg-gray-100 text-gray-800 rounded-lg shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-bold mb-4 text-center">Create or Join a Game</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Create a Game */}
            <div
              onClick={handleCreateGame}
              className="bg-gradient-to-r from-green-600 to-green-400 p-6 rounded-lg shadow-lg text-center transform hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
            >
              <FaPlusCircle className="text-4xl text-white mb-4 mx-auto" />
              <h3 className="text-xl font-bold mb-2 text-white">Create a Game</h3>
              <p className="text-white">Join in on the fun and create a game of your choice!</p>
            </div>

            {/* Join a Game */}
            <div className="bg-gradient-to-r from-green-600 to-green-400 p-6 rounded-lg shadow-lg text-center">
              <h3 className="text-xl font-bold mb-2 text-white">Join a Game</h3>
              <input
                type="text"
                placeholder="Enter Game ID"
                value={gameId}
                onChange={(e) => setGameId(e.target.value)}
                className="border border-gray-300 rounded-lg py-2 px-4 mb-4 w-full"
              />
              <button
                onClick={handleJoinGame}
                className="bg-white text-green-700 font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 ease-in-out w-full"
              >
                Join Game
              </button>
              {error && <p className="text-red-600 mt-2">{error}</p>}
            </div>
          </div>
        </div>

        {/* Available Games Section */}
        <AvailableGames games={games} isLoading={isLoading} />

        {/* Rankings Section */}
        <Rankings rankings={{ individual: [], team: [], general: [] }} />
      </div>

      <Footer />
    </div>
  );
}

export default Dashboard;
