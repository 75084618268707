import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import { useParams, useNavigate } from 'react-router-dom';
import royalFlushImg from '../../assets/images/royal_flush.png';
import straightFlushImg from '../../assets/images/straight_flush.png';
import fourOfAKindImg from '../../assets/images/four_of_a_kind.png';
import fullHouseImg from '../../assets/images/full_house.png';
import flushImg from '../../assets/images/flush.png';
import straightImg from '../../assets/images/straight.png';
import threeOfAKindImg from '../../assets/images/three_of_a_kind.png';
import twoPairImg from '../../assets/images/two_pair.png';
import onePairImg from '../../assets/images/one_pair.png';
import highCardImg from '../../assets/images/high_card.png';
import api from '../../api/axiosInstane';
import { formatDistanceToNow } from 'date-fns';

const GamePage = () => {
  const { game_id } = useParams();
  const navigate = useNavigate();
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    const fetchGameData = async () => {
      try {
        const response = await api.get(`/api/games/games/${game_id}`);
        setGameData(response.data);
      } catch (error) {
        console.error('Error fetching game data:', error);
      }
    };

    fetchGameData();
  }, [game_id]);

  const getGameImage = (gameName) => {
    switch (gameName) {
      case 'Royal Flush':
        return royalFlushImg;
      case 'Straight Flush':
        return straightFlushImg;
      case 'Four of a Kind':
        return fourOfAKindImg;
      case 'Full House':
        return fullHouseImg;
      case 'Flush':
        return flushImg;
      case 'Straight':
        return straightImg;
      case 'Three of a Kind':
        return threeOfAKindImg;
      case 'Two Pair':
        return twoPairImg;
      case 'One Pair':
        return onePairImg;
      case 'High Card':
        return highCardImg;
      default:
        return highCardImg;
    }
  };

  const timeFromNow = (time) => {
    return formatDistanceToNow(new Date(time), { addSuffix: true });
  };

  const handleJoinGame = () => {
    if (gameData.game_name === 'High Card') {
      navigate(`/game/${game_id}`);
    } else {
      const combination = {
        name: gameData.game_name,
        price: gameData.price || '10.00',
      };
      navigate('/payment', { state: { combination } });
    }
  };

  if (!gameData) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-green-50 min-h-screen flex flex-col justify-between">
      <Header />

      <div className="container mx-auto p-6 pt-[76px] md:pt-[400px] flex-grow">
        {/* Game Information Card */}
        <div className="bg-white rounded-lg shadow-lg p-8 mb-8 text-gray-800">
          <h2 className="text-3xl font-bold mb-4 text-green-700">{gameData.game_name}</h2>
          <img
            src={getGameImage(gameData.game_name)}
            alt={gameData.game_name}
            className="w-full h-56 md:h-72 object-cover rounded-lg mb-6"
          />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-lg">
            <p><strong>Status:</strong> {gameData.status}</p>
            <p><strong>Total Players:</strong> {gameData.total_players}</p>
            <p><strong>Current Players:</strong> {gameData.current_players}</p>
            <p><strong>Current Round:</strong> {gameData.current_round}</p>
            <p><strong>Game Created:</strong> {timeFromNow(gameData.created_at)}</p>
            <p><strong>Last Active:</strong> {timeFromNow(gameData.last_active_time)}</p>
          </div>

          <div className="mt-8 flex justify-center">
            {gameData.userIsJoined ? (
              <button className="bg-blue-600 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-blue-700 transition duration-300">
                Invite Players
              </button>
            ) : (
              <button
                className="bg-green-600 text-white py-3 px-8 rounded-lg shadow-lg hover:bg-green-700 transition duration-300"
                onClick={handleJoinGame}
              >
                Join Game
              </button>
            )}
          </div>
        </div>

        {/* Players Information */}
        <div className="bg-white rounded-lg shadow-lg p-8">
          <h3 className="text-2xl font-semibold mb-6 text-gray-700">Players</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {gameData.players.map((player, index) => (
              <div key={index} className="bg-gray-100 p-6 rounded-lg shadow-md">
                <img
                  src={player.user_image}
                  alt={player.username}
                  className="w-24 h-24 rounded-full mx-auto mb-4"
                />
                <h4 className="text-lg font-semibold text-center mb-2">{player.username}</h4>
                <p className="text-center text-gray-600"><strong>Chips:</strong> {player.available_chips}</p>
                <p className="text-center text-gray-600"><strong>Status:</strong> {player.status}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

};

export default GamePage;
