import React from 'react';
import { FaInstagram, FaFacebook, FaLinkedin, FaDiscord, FaTelegramPlane, FaPaypal } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-b from-green-800 to-green-600 text-white py-8">
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Contact Us */}
          <div>
            <h3 className="text-xl font-bold mb-4">Contact Us</h3>
            <p className="mb-2">Email: support@mylittlerivercard.com</p>
            <p className="mb-2">Phone: +33 3 66 47 78 30</p>
            </div>

          {/* Download Options */}
          <div>
            <h3 className="text-xl font-bold mb-4">Download</h3>
            <p className="mb-2">Mobile App</p>
            <p className="mb-2">Desktop App</p>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-xl font-bold mb-4">Follow Us</h3>
            <div className="flex space-x-4">
              <a href="https://www.instagram.com/mylittlerivercard/" aria-label="Instagram" className="text-white hover:text-gray-300"><FaInstagram size={24} /></a>
              <a href="https://www.facebook.com/profile.php?id=61563412694114" aria-label="Facebook" className="text-white hover:text-gray-300"><FaFacebook size={24} /></a>
              <a href="https://www.linkedin.com/" aria-label="LinkedIn" className="text-white hover:text-gray-300"><FaLinkedin size={24} /></a>
              <a href="https://discord.com/" aria-label="Discord" className="text-white hover:text-gray-300"><FaDiscord size={24} /></a>
              <a href="https://web.telegram.org/" aria-label="Telegram" className="text-white hover:text-gray-300"><FaTelegramPlane size={24} /></a>
            </div>
          </div>
        </div>

        {/* Payment Methods */}
        <div className="mt-8">
          <h3 className="text-xl font-bold mb-4">Payment Methods</h3>
          <div className="flex space-x-4">
            <FaPaypal size={24} />
            {/* Add other payment icons here */}
          </div>
        </div>

        <p className="text-center mt-8">&copy; 2024 MyLittleRiverCard. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
