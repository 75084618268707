import React, { useEffect, useState } from 'react';
import hidden_card from '../../../assets/images/cards/hiden_card1.png';
import countdown_sound from '../../../assets/sounds/countdown_sound.wav';
import { useSelector, useDispatch } from 'react-redux';
import { startGame } from '../services/websocket';
import { useParams } from 'react-router-dom';
import { decrementCountdown } from '../redux/gameSlice';

function GameTable({ children }) {
  const dispatch = useDispatch();
  const { game_id } = useParams();

  const { communityCards, status, countdown } = useSelector(
    (state) => state.game
  );

  const [waitingForPlayers, setWaitingForPlayers] = useState(false);

  const {
    flop_card1,
    flop_card2,
    flop_card3,
    turn_card,
    river_card,
  } = communityCards || {};

  const visibleCommunityCards = [
    flop_card1,
    flop_card2,
    flop_card3,
    turn_card,
    river_card,
  ].filter((card) => card !== null);

  const handleStartClick = () => {
    startGame(game_id);
    setWaitingForPlayers(true);
  };

  useEffect(() => {
    let interval;
    let sound;

    if (status === 'starting' && countdown > 0) {
      setWaitingForPlayers(false);
      sound = new Audio(countdown_sound);
      sound.play().catch((error) => {
        console.error('Sound play failed:', error);
      });

      interval = setInterval(() => {
        dispatch(decrementCountdown());
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      if (sound) {
        sound.pause();
        sound.currentTime = 0;
      }
    };
  }, [dispatch, status, countdown]);

  // Determine the current game stage and set burn cards
  let burnedCardsCount = 0;
  if (visibleCommunityCards.length >= 3) {
    // After the flop, show 1 burned card
    burnedCardsCount = 1;
  }
  if (visibleCommunityCards.length >= 4) {
    // After the turn, show 2 burned cards
    burnedCardsCount = 2;
  }
  if (visibleCommunityCards.length === 5) {
    // After the river, show all 3 burned cards
    burnedCardsCount = 3;
  }

  useEffect(() => {
    if (status === 'round-completed') {
      startGame(game_id);
      setWaitingForPlayers(true);
    }
  }, [status, game_id]);
  

  return (
    <div className="oval bg-[#15803d] w-[85vw] h-[70vh] rounded-[70%] shadow-lg -mt-28 flex items-center justify-center relative">
      {/* Community Cards */}
      <div className="flex gap-2 sm:gap-4" style={{ marginTop: '-6rem' }}>
        {visibleCommunityCards.map((card, index) => (
          <img
            key={index}
            src={require(`../../../assets/images/cards/${card}.png`)}
            alt={`Card ${index + 1}`}
            className="w-16 h-24 object-contain sm:w-24 sm:h-36"
          />
        ))}
      </div>

      {/* Burned Cards */}
      <div
        className="absolute right-8 sm:right-12 flex"
        style={{ marginRight: '350px', marginTop: '-500px' }}
      >
        {Array(burnedCardsCount)
          .fill()
          .map((_, index) => (
            <img
              key={`burned-${index}`}
              src={hidden_card}
              alt={`Burned Card ${index + 1}`}
              className="w-12 h-18 object-contain -rotate-12 sm:w-16 sm:h-24"
              style={{
                transform: `rotate(${15 + index * 10}deg)`,
                marginLeft: index === 0 ? '0px' : '-15px',
              }}
            />
          ))}
      </div>

      {/* Game Status Display */}
      {status === 'waiting' && !waitingForPlayers ? (
        <button
          onClick={handleStartClick}
          className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full text-xl shadow-lg"
          style={{
            width: '200px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '2rem',
            fontFamily: 'Comic Sans MS, sans-serif',
          }}
        >
          START
        </button>
      ) : (status === 'waiting' || status === 'round-completed') && waitingForPlayers ? (
        <div className="text-white text-xl font-bold animate-pulse mt-8">
          Waiting for other players to accept...
        </div>
      ) : status === 'starting' && countdown > 0 ? (
        <div className="text-white text-4xl font-bold animate-pulse mt-8">
          Game starting in {countdown}...
        </div>
      ) : null}

      {children}
    </div>
  );
}

export default GameTable;
