import React from 'react';
import { useNavigate } from 'react-router-dom'
import EmailPng from '../../assets/images/mail.png';
import GooglePng from '../../assets/images/google.png';
import FacebookPng from '../../assets/images/facebook.png';
import MainBannerBg from '../../assets/images/mainbar-background-img.jpeg';
import card1 from '../../assets/images/card1.png';
import card2 from '../../assets/images/card2.png';
import card3 from '../../assets/images/card3.png';
import card4 from '../../assets/images/card4.png';
import card5 from '../../assets/images/card5.png';
import hand1 from '../../assets/images/hand1.png';
import hand2 from '../../assets/images/hand2.png';
import hand3 from '../../assets/images/hand3.png';
import hand4 from '../../assets/images/hand4.png';

function HeroSection() {

  const navigate = useNavigate();  

  const handleSubmit = () => {
    navigate('/login');
  };

  const handleGoogleLogin = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    window.location.href = `${apiBaseUrl}/api/auth/google`;
  };

  const handleFacebookLogin = () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    window.location.href = `${apiBaseUrl}/api/auth/facebook`;
  };

  return (
    <section
      className="bg-[#2d902e] bg-cover bg-center flex items-center justify-center relative"
      style={{ height: '92vh', backgroundImage: `url(${MainBannerBg})` }}
    >
      {/* Hand1 Image Positioned on the Left Side */}
      <img
        src={hand1}
        alt="Hand 1"
        className="absolute left-0 transform -translate-y-1/2"
        style={{ top: '50%', width: '20%', height: 'auto' }}
      />

      {/* Hand2 Image Positioned at the Bottom, Centered */}
      <img
        src={hand2}
        alt="Hand 2"
        className="absolute bottom-0 transform translate-x-1/2"
        style={{
          left: '0%',
          width: '20%',
          height: 'auto',
        }}
      />

      {/* Hand3 Image Positioned at the Bottom, Right Side */}
      <img
        src={hand3}
        alt="Hand 3"
        className="absolute bottom-0 right-0"
        style={{
          width: '20%',
          height: 'auto',
        }}
      />

      {/* Hand4 Image Positioned on the Right Side, Halfway Down */}
      <img
        src={hand4}
        alt="Hand 4"
        className="absolute right-0 transform -translate-y-1/2"
        style={{ top: '40%', width: '20%', height: 'auto' }}
      />

      <div className="text-center w-full max-w-md px-4" style={{ marginBottom: '20%' }}>
        <div className="flex items-center space-x-2 mb-4">
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Enter your Email"
              className="w-full px-4 py-2 pl-10 rounded-l-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-500"
            />
            <img
              src={EmailPng}
              alt="Email"
              className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5"
            />
          </div>
          <button
            type="submit"
            className="bg-[#426cba] text-white px-4 py-2 rounded-r-md hover:bg-[#2d902e] focus:outline-none focus:ring-2 focus:ring-green-500"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>

        <div className="space-y-2 ml-4">
          {/* Continue with Google Button */}
          <button
            className="relative flex items-center justify-center bg-white text-gray-700 px-4 py-2 mb-2 rounded-md border border-gray-300 hover:bg-gray-100"
            style={{ width: '75%' }}
            onClick={handleGoogleLogin}
          >
            <img
              src={GooglePng}
              alt="Google"
              className="absolute left-4 w-5 h-5"
            />
            <span className="w-full text-center">Continue with Google</span>
          </button>
          {/* Continue with Facebook Button */}
          <button
            className="relative flex items-center justify-center bg-white text-gray-700 px-4 py-2 rounded-md hover:bg-gray-100"
            style={{ width: '75%' }}
            onClick={handleFacebookLogin}
          >
            <img
              src={FacebookPng}
              alt="Facebook"
              className="absolute left-4 w-5 h-5"
            />
            <span className="w-full text-center">Continue with Facebook</span>
          </button>
        </div>

        {/* Cards Section */}
        <div className="flex justify-center mt-6 space-x-4 ml-[-16%]">
          <img src={card1} alt="Card 1" className="w-12 h-20 sm:w-16 sm:h-24 object-cover" />
          <img src={card2} alt="Card 2" className="w-12 h-20 sm:w-16 sm:h-24 object-cover" />
          <img src={card3} alt="Card 3" className="w-12 h-20 sm:w-16 sm:h-24 object-cover" />
          <img src={card4} alt="Card 4" className="w-12 h-20 sm:w-16 sm:h-24 object-cover" />
          <img
            src={card5}
            alt="Card 5"
            className="w-12 h-20 sm:w-16 sm:h-24 object-cover animate-shine"
            style={{ width: '15%' }}
          />
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
