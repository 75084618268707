import React, { useState, useEffect } from 'react';
import api from '../../../api/axiosInstane';

const GameStatistics = ({ gameId, onClose }) => {
  const [gameDetails, setGameDetails] = useState(null);
  const [openRound, setOpenRound] = useState(null);
  const [roundDetails, setRoundDetails] = useState({});
  const [invitedPlayers, setInvitedPlayers] = useState(new Set());

  useEffect(() => {
    const fetchGameDetails = async () => {
      try {
        const response = await api.get(`/api/games/${gameId}/details`);
        setGameDetails(response.data);
      } catch (error) {
        console.error('Error fetching game details:', error);
      }
    };

    fetchGameDetails();
  }, [gameId]);

  const toggleRoundDetails = async (roundId, index) => {
    if (openRound === index) {
      setOpenRound(null);
    } else {
      try {
        const response = await api.get(`/api/games/round/${roundId}`);
        setRoundDetails((prev) => ({ ...prev, [index]: response.data }));
        setOpenRound(index);
      } catch (error) {
        console.error('Error fetching round details:', error);
      }
    }
  };

  const getImage = (imageUrl) => {
    return imageUrl ? imageUrl : 'https://via.placeholder.com/80';
  };

  const handleInvitePlayer = (playerId) => {
    if (!invitedPlayers.has(playerId)) {
      setInvitedPlayers((prev) => new Set(prev).add(playerId));
      console.log(`Invitation sent to player ${playerId}`);
    }
  };

  if (!gameDetails) {
    return <div>Loading...</div>;
  }

  const { players, ranking, points, rounds } = gameDetails;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 overflow-auto">
      <div className="relative bg-[#146935] text-white rounded-lg shadow-lg max-w-6xl w-full p-6 mx-4 sm:mx-8 md:mx-12 lg:mx-16 h-auto max-h-[80vh] overflow-y-auto">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white bg-red-500 hover:bg-red-600 w-8 h-8 rounded-full flex items-center justify-center"
        >
          X
        </button>

        <h2 className="text-2xl font-bold mb-6 text-center">Game Statistics</h2>

        {/* Players Section */}
        <section className="mb-6">
          <h3 className="text-xl font-semibold mb-4">Players</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 overflow-x-auto">
            {players.map((player) => (
              <div key={player.player_id} className="bg-white text-black p-4 rounded-lg shadow-lg text-center hover:bg-green-600 hover:text-white transition-all">
                <img
                  src={getImage(player.user_image)}
                  alt={`${player.username} avatar`}
                  className="w-20 h-20 rounded-full mx-auto mb-3"
                />
                <p className="font-semibold">{player.username}</p>
                <p>Chips: {player.available_chips}</p>
                <p className={`text-sm ${player.status === 'disconnected' ? 'text-red-400' : ''}`}>{player.status === 'disconnected' ? 'Disconnected' : 'Connected'}</p>

                {player.status === 'disconnected' && !invitedPlayers.has(player.player_id) && (
                  <button
                    onClick={() => handleInvitePlayer(player.player_id)}
                    className="mt-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-full w-full transition-all"
                  >
                    Invite
                  </button>
                )}
                {invitedPlayers.has(player.player_id) && (
                  <button
                    disabled
                    className="mt-4 bg-gray-400 text-white py-2 px-4 rounded-full w-full cursor-not-allowed"
                  >
                    Invited
                  </button>
                )}
              </div>
            ))}
          </div>
        </section>

        {/* Ranking and Points */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          {ranking && ranking.length > 0 && (
            <div className="bg-white text-black p-4 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Ranking</h3>
              <ul className="space-y-3">
                {ranking.sort((a, b) => b.rounds_won - a.rounds_won).map((player) => (
                  <li key={player.username} className="flex items-center justify-between bg-white text-black p-2 rounded hover:bg-gray-200 transition-all">
                    <img src={getImage(player.user_image)} alt={`${player.username} avatar`} className="w-10 h-10 rounded-full mr-3" />
                    <span>{player.username}</span>
                    <span>Rounds Won: {player.rounds_won}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {points && points.length > 0 && (
            <div className="bg-white text-black p-4 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold mb-4">Points</h3>
              <div className="overflow-x-auto">
                <table className="w-full bg-white text-black rounded-lg">
                  <thead>
                    <tr className="bg-gray-300">
                      <th className="p-2 text-left">Player</th>
                      <th className="p-2 text-left">Image</th>
                      {Array.from({ length: Math.max(...points.map(p => p.points_earned.length)) }).map((_, index) => (
                        <th key={index} className="p-2 text-left">Level {index + 1}</th>
                      ))}
                      <th className="p-2 text-left">Total Points</th>
                    </tr>
                  </thead>
                  <tbody>
                    {points.map((playerPoints, index) => {
                      const totalPoints = playerPoints.points_earned.reduce((acc, point) => acc + point, 0);
                      return (
                        <tr key={index} className="border-b">
                          <td className="p-2">{playerPoints.username}</td>
                          <td className="p-2">
                            <img
                              src={getImage(playerPoints.user_image)}
                              alt={`${playerPoints.username} avatar`}
                              className="w-10 h-10 rounded-full"
                            />
                          </td>
                          {playerPoints.points_earned.map((point, pointIndex) => (
                            <td key={pointIndex} className={`p-2 ${point >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                              {point > 0 ? `+${point}` : point}
                            </td>
                          ))}
                          {/* Empty cells for rounds not participated in */}
                          {Array.from({ length: Math.max(...points.map(p => p.points_earned.length)) - playerPoints.points_earned.length })
                            .map((_, i) => (
                              <td key={`empty-${i}`} className="p-2 text-gray-400">-</td>
                            ))}
                          <td className="p-2 font-bold">{totalPoints}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}

        </div>

        {/* Round Summary */}
        <section className="bg-white text-black p-4 rounded-lg shadow-lg mb-6">
          <h3 className="text-xl font-semibold mb-4">Rounds Summary</h3>
          <div className="space-y-4">
            {Object.entries(rounds).map(([roundName, roundId], index) => (
              <div key={roundName} className="bg-gray-100 p-3 rounded">
                <div className="flex justify-between items-center">
                  <h4 className="font-semibold">{roundName}</h4>
                  <button
                    onClick={() => toggleRoundDetails(roundId, index)} // Use roundId here
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {openRound === index ? 'Hide Details' : 'Show Details'}
                  </button>
                </div>
                {openRound === index && roundDetails[index] && (
                  <div className="mt-4">
                    <p><strong>Status:</strong> {roundDetails[index].status}</p>
                    <p><strong>Pot :</strong> {roundDetails[index].pot_value}</p>
                    <p><strong>Winner:</strong> {roundDetails[index].winner}</p>
                    <div className="flex space-x-2">
                      <strong>Community Cards:</strong>
                      <img src={require(`../../../assets/images/cards/${roundDetails[index].community_cards.flop[0]}.png`)} alt="Flop Card 1" className="w-12 h-16" />
                      <img src={require(`../../../assets/images/cards/${roundDetails[index].community_cards.flop[1]}.png`)} alt="Flop Card 2" className="w-12 h-16" />
                      <img src={require(`../../../assets/images/cards/${roundDetails[index].community_cards.flop[2]}.png`)} alt="Flop Card 3" className="w-12 h-16" />
                      <img src={require(`../../../assets/images/cards/${roundDetails[index].community_cards.turn}.png`)} alt="Turn Card" className="w-12 h-16" />
                      <img src={require(`../../../assets/images/cards/${roundDetails[index].community_cards.river}.png`)} alt="River Card" className="w-12 h-16" />
                    </div>
                    <div className="mt-4">
                      <strong>Players:</strong>
                      {roundDetails[index].players.map((player) => (
                        <div key={player.username} className="flex items-center space-x-2 mt-2">
                          <img
                            src={getImage(player.user_image)}
                            alt={player.username}
                            className="w-10 h-10 rounded-full"
                          />
                          <div>
                            <p>{player.username} - {player.blind_status}</p>
                            <div className="flex space-x-2">
                              <img
                                src={require(`../../../assets/images/cards/${player.cards.card1}.png`)}
                                alt={`Card 1 of ${player.username}`}
                                className="w-12 h-16"
                              />
                              <img
                                src={require(`../../../assets/images/cards/${player.cards.card2}.png`)}
                                alt={`Card 2 of ${player.username}`}
                                className="w-12 h-16"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mt-4">
                      <strong>Bets:</strong>
                      <table className="w-full bg-gray-100 rounded mt-2">
                        <thead>
                          <tr className="bg-gray-300">
                            <th className="p-2 text-left">Username</th>
                            <th className="p-2 text-left">Bet Type</th>
                            <th className="p-2 text-left">Chips Bet</th>
                          </tr>
                        </thead>
                        <tbody>
                          {roundDetails[index].bets.map((bet, betIndex) => (
                            <tr key={betIndex} className="border-b">
                              <td className="p-2">{bet.username}</td>
                              <td className="p-2 capitalize">{bet.bet_type}</td>
                              <td className="p-2">{bet.chips_bet}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default GameStatistics;
