import React from 'react';
import './LoadingScreen.css';

const LoadingScreen = ({ progress }) => {
  return (
    <div className={`flex items-center justify-center h-screen bg-black`}>
      <div className="text-center">
        <p className="text-2xl text-white mb-4">LOADING...</p>
        <div className="relative w-64 h-4 bg-gray-700 rounded">
          <div
            className="absolute top-0 left-0 h-4 bg-teal-400 rounded loading-bar"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <p className="text-white mt-2">{progress}%</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
