import React from 'react';
import { useNavigate } from 'react-router-dom';
import background from '../../assets/images/poker-card-background-img.jpg';
import logo from '../../assets/images/black-logo.png';

const HeroSection = () => {
    const navigate = useNavigate();

    const handleStartPlaying = () => {
        navigate('/dashboard');
    };

    return (
        <section 
            className="relative flex items-center justify-center h-screen bg-cover bg-center"
            style={{ backgroundImage: `url(${background})` }}
        >
            <div className="absolute inset-0 bg-black opacity-60"></div>
            <div className="relative z-10 text-center text-white px-4 py-12 md:px-8 lg:px-16">
                <img 
                    src={logo} 
                    alt="Logo" 
                    className="mx-auto mb-4 md:mb-6 w-24 md:w-32 lg:w-40 animate__animated animate__fadeIn"
                    loading="lazy"
                />
                <h1 className="text-3xl md:text-5xl lg:text-6xl font-extrabold mb-4 md:mb-6 lg:mb-8 leading-tight animate__animated animate__fadeIn animate__delay-1s">
                    Welcome to MyLittleRiverCard
                </h1>
                <p className="text-lg md:text-xl lg:text-2xl mb-6 md:mb-8 lg:mb-12 mx-auto max-w-2xl lg:max-w-3xl animate__animated animate__fadeIn animate__delay-2s">
                    Play poker with a modern twist—anytime, anywhere. Join now and experience the excitement of the game like never before!
                </p>
                <button 
                    className="bg-gradient-to-r from-green-400 to-green-600 hover:from-green-500 hover:to-green-700 text-white font-bold py-2 px-6 md:py-3 md:px-8 rounded-full shadow-lg transition duration-300 ease-in-out animate__animated animate__fadeIn animate__delay-3s"
                    onClick={handleStartPlaying}
                    aria-label="Start Playing"
                >
                    Start Playing
                </button>
            </div>
        </section>
    );
};

export default HeroSection;
