import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activateAccount } from '../../../redux/features/auth/authSlice';
import './activate.css';

function Activate() {
  const { token } = useParams();
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const [activationStatus, setActivationStatus] = useState(null);
  const dispatch = useDispatch();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 90 ? prevProgress : prevProgress + 10));
    }, 100);

    dispatch(activateAccount(token))
      .then((response) => {
        clearInterval(progressInterval);
        setProgress(100);
        setActivationStatus('success');
        const redirectTimeout = setTimeout(() => {
          navigate('/validate-otp');
        }, 2000);
        return () => clearTimeout(redirectTimeout);
      })
      .catch((error) => {
        clearInterval(progressInterval);
        setProgress(100);
        setActivationStatus('error');
      });

    return () => clearInterval(progressInterval);
  }, [apiBaseUrl, token, dispatch, navigate]);

  return (
    <div className='container'>
      <div className='activation-container'>
        <h1 className='activation-heading'>Verifying your Account</h1>
        <div className='progress-bar'>
          <div className='progress-bar-inner' style={{ width: `${progress}%` }}></div>
        </div>
        <p className='activation-text'>Please be patient. Your account will be verified shortly.</p>
        {activationStatus === 'success' && (
          <p className='success-message'>
            Your account has been successfully activated!
            <span role="img" aria-label="success"> 🎉</span>
          </p>
        )}
        {activationStatus === 'error' && (
          <p className='error-message'>
            There was an error activating your account. Please try again.
            <span role="img" aria-label="error"> ❌</span>
          </p>
        )}
      </div>
    </div>
  );
}

export default Activate;
