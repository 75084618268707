import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/bootstrap.css';
import {useNavigate} from 'react-router-dom';


const PhoneVerification = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [token] = useState(localStorage.getItem('token') || '');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handlePhoneNumberSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const formattedPhone = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;

      const response = await fetch(`${apiBaseUrl}/api/mobile/add-phone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ phoneNumber: formattedPhone }),
      });

      if (response.ok) {
        console.log('Code sent successfully to', formattedPhone);
        setStep(2);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to send code');
        console.error('Failed to send code:', response.status, response.statusText);
      }
    } catch (error) {
      setError('Error sending code');
      console.error('Error sending code:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${apiBaseUrl}/api/mobile/verify-phone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ code: otp }),
      });

      if (response.ok) {
        console.log('Verification successful');
        navigate('/dashboard'); // Uncomment if using react-router
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Verification failed');
        console.error('Verification failed:', response.status, response.statusText);
      }
    } catch (error) {
      setError('Error verifying code');
      console.error('Error verifying code:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-400 to-blue-500">
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-bold text-center text-gray-700 mb-6">
          Phone Verification
        </h1>

        {error && (
          <div className="text-red-500 text-center mb-4">
            {error}
          </div>
        )}

        {step === 1 && (
          <form onSubmit={handlePhoneNumberSubmit}>
            <div className="mb-6">
              <label className="block text-gray-600 font-medium mb-2" htmlFor="phoneNumber">
                Phone Number
              </label>
              <PhoneInput
                country={'us'}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                inputStyle={{
                  paddingTop: 26,
                  paddingLeft: 58,
                  width: "100%",
                }}
                inputClass="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
              />
            </div>

            <button
              type="submit"
              className={`w-full bg-green-500 text-white p-3 rounded-md font-semibold transition duration-300 ${
                loading ? 'cursor-not-allowed' : 'hover:bg-green-600'
              }`}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 16a4 4 0 100-8 4 4 0 000 8z"
                    />
                  </svg>
                  Sending...
                </div>
              ) : (
                'Send Verification Code'
              )}
            </button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleOtpSubmit}>
            <div className="mb-6">
              <label className="block text-gray-600 font-medium mb-2" htmlFor="otp">
                Enter OTP
              </label>
              <input
                id="otp"
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="Enter the OTP sent to your phone"
                required
              />
            </div>

            <button
              type="submit"
              className={`w-full bg-green-500 text-white p-3 rounded-md font-semibold transition duration-300 ${
                loading ? 'cursor-not-allowed' : 'hover:bg-green-600'
              }`}
              disabled={loading}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 16a4 4 0 100-8 4 4 0 000 8z"
                    />
                  </svg>
                  Verifying...
                </div>
              ) : (
                'Verify OTP'
              )}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PhoneVerification;
