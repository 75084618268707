import React, { useState } from 'react';
import TimePicker from 'react-time-picker'; // Import the TimePicker component
import 'react-time-picker/dist/TimePicker.css'; // Import TimePicker's CSS

const SetTimeModal = ({ isOpen, onClose, onSave, gameId }) => {
  const [time, setTime] = useState(new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));

  const handleSave = () => {
    onSave(gameId, time);
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm">
      {/* Modal Wrapper */}
      <div className="transform transition-transform duration-500 ease-out scale-100 opacity-100 bg-white p-8 rounded-xl shadow-2xl relative w-96 max-w-full">
        {/* Modal Header */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
          Set Game Time
        </h2>

        {/* Digital Clock (TimePicker) */}
        <div className="flex justify-center mb-6">
          <TimePicker
            onChange={setTime}
            value={time}
            disableClock={true}
            className="react-time-picker__inputGroup"
            clearIcon={null} // Hide the clear icon
            clockIcon={null} // Hide the clock icon
          />
        </div>

        {/* Display selected time */}
        <div className="text-center mb-4 text-lg font-semibold text-gray-600">
          Selected time: <span className="text-green-600">{time}</span>
        </div>

        {/* Modal Actions */}
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg font-semibold hover:bg-gray-400 transition-all"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-green-600 text-white rounded-lg font-semibold hover:bg-green-500 transition-all shadow-md"
          >
            Save
          </button>
        </div>

        {/* Close Icon */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-all"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      {/* Custom Styles for the TimePicker */}
      <style jsx>{`
        .react-time-picker__inputGroup {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px solid #009249; /* Green border */
          border-radius: 8px; /* Rounded corners */
          padding: 10px; /* Padding for input group */
        }
        .react-time-picker__inputGroup__input {
          border: none; /* Remove default input border */
          outline: none; /* Remove outline */
          font-size: 1.5rem; /* Larger font size */
          color: #333; /* Input text color */
          padding: 0.5rem; /* Padding for input */
        }
        .react-time-picker__inputGroup__input:focus {
          border-color: #009249; /* Border color on focus */
        }
        .react-time-picker__inputGroup__hour {
          color: #009249; /* Green color for hour input */
        }
        .react-time-picker__inputGroup__minute {
          color: #009249; /* Green color for minute input */
        }
      `}</style>
    </div>
  );
};

export default SetTimeModal;
