import React, { useState } from 'react';
import InvitePlayersModal from './InvitePlayersModal';


const PlayerInviteScreen = ({ game_id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const gameInviteLink = `https://www.mylittlerivercard.com/game-details/${game_id}`;

    return (
        <div className="flex items-center justify-center h-screen bg-black">
            <div className="relative w-full max-w-3xl border-4 border-lime-500 p-6 rounded-lg">
                {/* Close Icons */}
                <div className="absolute top-0 left-0 m-4 text-white text-3xl">✖</div>
                <div className="absolute bottom-0 right-0 m-4 text-white text-3xl">✖</div>

                {/* Main Text */}
                <h1 className="text-center text-lime-500 text-5xl md:text-6xl font-bold animate-glitch">
                    NO PLAYERS RIGHT NOW
                </h1>

                {/* Invite Button */}
                <div className="mt-10 flex justify-center">
                    <button
                        className="px-8 py-4 bg-white text-black font-bold text-lg rounded-md hover:bg-gray-200 transition duration-300 ease-in-out"
                        onClick={() => setIsModalOpen(true)} // Open modal on click
                    >
                        Invite players
                    </button>
                </div>
            </div>

            {/* Invite Players Modal */}
            <InvitePlayersModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                gameInviteLink={gameInviteLink}
            />
        </div>
    );
};

export default PlayerInviteScreen;
