import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../redux/features/auth/authSlice'
import gameReducer from '../modules/gamePage/redux/gameSlice'

export const store = configureStore({
 reducer: {
    auth: authReducer,
    game : gameReducer,

 },
 devTools: process.env.NODE_ENV !== 'production',


})