import React from 'react';
import { useNavigate } from 'react-router-dom';

function Header() {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login'); 
  };

  return (
    <header className="bg-[#005900] p-4 flex justify-between items-center">
      <div className='ml-auto'>
        <button 
          className="bg-[#3255a1] text-white py-2 px-4 rounded mr-4 hover:bg-[#27478e] w-32"
          onClick={handleLoginClick}
        >
          Login
        </button>
        <button 
          className="bg-[#38b138] text-white py-2 px-4 rounded hover:bg-[#2d902e]"
        >
          Our Data
        </button>
      </div>
    </header>
  );
}

export default Header;
