import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import highCardImg from '../../../assets/images/high_card.png';
import royalFlushImg from '../../../assets/images/royal_flush.png';
import InvitePlayersModal from '../../gamePage/components/InvitePlayersModal';
import SetTimeModal from './SetTimeModal';

const AvailableGames = ({ games, isLoading }) => {
  const navigate = useNavigate(); 
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false); 
  const [isSetTimeModalOpen, setIsSetTimeModalOpen] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [gameInviteLink, setGameInviteLink] = useState(''); 

  // Function to map game names to images
  const getGameImage = (gameName) => {
    switch (gameName) {
      case 'Royal Flush':
        return royalFlushImg;
      case 'High Card':
        return highCardImg;
      default:
        return null;
    }
  };

  // Function to format last play time
  const formatLastPlayTime = (lastActiveTime) => {
    return formatDistanceToNow(new Date(lastActiveTime), { addSuffix: true });
  };

  // Function to handle the "Start" button click
  const handleStartGame = (gameId) => {
    navigate(`/game/${gameId}`); // Navigate to the game route
  };

  // Function to handle the "Invite" button click
  const handleInvite = (gameId) => {
    const inviteLink = `https://www.mylittlerivercard.com/game-details/${gameId}`;
    setGameInviteLink(inviteLink);
    setIsInviteModalOpen(true); // Open the modal
  };

  // Function to handle the "Set Time" button click
  const handleSetTime = (gameId) => {
    setSelectedGameId(gameId); // Set the selected game ID for setting time
    setIsSetTimeModalOpen(true); // Open the SetTime modal
  };

  // Function to save the selected time
  const handleSaveTime = (gameId, selectedTime) => {
    console.log(`Setting time for game ${gameId} to ${selectedTime}`);
    // Add logic to update the game with the selected time (e.g., API call)
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="flex flex-col items-center space-y-4">
          <div className="w-16 h-16 border-8 border-t-8 border-green-500 border-solid rounded-full animate-spin"></div>
          <p className="text-lg font-semibold text-gray-200">Loading available games...</p>
        </div>
      </div>
    );
  }

  if (games.length === 0) {
    return (
      <div className="text-center text-gray-400 py-8">
        <p className="text-xl font-bold mb-4">No games available at the moment.</p>
        <p className="text-lg">It looks like there are no ongoing games right now. Be the first to set up a new game and invite your friends!</p>
        <button 
          onClick={() => window.location.href = '/create-game'} 
          className="mt-4 bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg"
        >
          Create a Game
        </button>
      </div>
    );
  }

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {games.map((game) => (
          <div
            key={game.id}
            className="bg-green-800 p-4 rounded-lg shadow-lg text-center transform hover:scale-105 transition duration-300 ease-in-out"
          >
            <img 
              src={getGameImage(game.game_name)} 
              alt={game.game_name} 
              className="mx-auto mb-4 w-30 h-24 rounded-lg"
            />
            <h2 className="text-2xl font-bold mb-2">{game.game_name}</h2>
            <p className="text-lg mb-2">Status: <span className="font-extrabold">{game.status}</span></p>
            <p className="text-lg mb-2">Current Round: <span className="font-extrabold">{game.current_round}</span></p>
            <p className="text-lg mb-2">Last Play: <span className="font-extrabold">{formatLastPlayTime(game.last_active_time)}</span></p>
            <div className="flex justify-around mt-4">
              <button 
                onClick={() => handleSetTime(game.game_id)} // Open SetTime modal for this game
                className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg"
              >
                Set Time
              </button>
              <button 
                onClick={() => handleInvite(game.game_id)} // Pass the game_id to handleInvite
                className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg"
              >
                Invite
              </button>
              <button 
                onClick={() => handleStartGame(game.game_id)} 
                className="bg-green-700 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg"
              >
                Start
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Invite Players Modal */}
      <InvitePlayersModal 
        isOpen={isInviteModalOpen} 
        onClose={() => setIsInviteModalOpen(false)} 
        gameInviteLink={gameInviteLink} 
      />

      {/* Set Time Modal */}
      <SetTimeModal 
        isOpen={isSetTimeModalOpen} 
        onClose={() => setIsSetTimeModalOpen(false)} 
        onSave={handleSaveTime} 
        gameId={selectedGameId}
      />
    </div>
  );
};

export default AvailableGames;
