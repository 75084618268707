import React from 'react';
import { useSelector } from 'react-redux';
import chip1 from '../../../assets/images/chip1.png';
import chip2 from '../../../assets/images/chip2.png';

function PotDisplay() {
  const { status, potValue } = useSelector((state) => state.game);
  if (status !== 'playing') return null;

  return (
    <div className="absolute bottom-1/2 transform translate-y-10 bg-[#1c1c1c] px-6 py-1 sm:px-8 sm:py-1.5 rounded-lg flex items-center gap-2 sm:gap-4">
      {/* Display Chips */}
      <div className="flex items-center">
        <img src={chip1} alt="Chip 1" className="w-6 h-6 sm:w-8 sm:h-8" />
        <img src={chip2} alt="Chip 2" className="w-6 h-6 sm:w-8 sm:h-8 -ml-2" />
      </div>

      {/* Pot Value */}
      <span className="text-white font-bold text-sm sm:text-base">
        POT {potValue}
      </span>
    </div>
  );
}

export default PotDisplay;
