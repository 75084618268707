// src/pages/Dashboard/components/Rankings.js
import React from 'react';

const Rankings = ({ rankings }) => {
  return (
    <div className="bg-gray-100 text-gray-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-bold mb-4 text-center">Rankings</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Individual Ranking */}
        <div className="bg-gradient-to-r from-red-600 to-red-400 p-4 rounded-lg shadow-lg text-center">
          <h3 className="text-xl font-bold mb-2 text-white">Individual Ranking</h3>
          <table className="w-full text-left border-collapse border border-gray-300 bg-white rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Player</th>
                <th className="px-4 py-2 border-b">Points</th>
              </tr>
            </thead>
            <tbody>
              {rankings.individual.map((player, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-b">{player.name}</td>
                  <td className="px-4 py-2 border-b">{player.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Team Ranking */}
        <div className="bg-gradient-to-r from-yellow-600 to-yellow-400 p-4 rounded-lg shadow-lg text-center">
          <h3 className="text-xl font-bold mb-2 text-white">Team Ranking</h3>
          <table className="w-full text-left border-collapse border border-gray-300 bg-white rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Player</th>
                <th className="px-4 py-2 border-b">Points</th>
              </tr>
            </thead>
            <tbody>
              {rankings.team.map((player, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-b">{player.name}</td>
                  <td className="px-4 py-2 border-b">{player.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* General Ranking */}
        <div className="bg-gradient-to-r from-blue-600 to-blue-400 p-4 rounded-lg shadow-lg text-center">
          <h3 className="text-xl font-bold mb-2 text-white">General Ranking</h3>
          <table className="w-full text-left border-collapse border border-gray-300 bg-white rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Player</th>
                <th className="px-4 py-2 border-b">Points</th>
              </tr>
            </thead>
            <tbody>
              {rankings.general.map((player, index) => (
                <tr key={index}>
                  <td className="px-4 py-2 border-b">{player.name}</td>
                  <td className="px-4 py-2 border-b">{player.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Rankings;
