import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from './components/loadingscreen/LoadingScreen';
import { connectToGame, getPlayersCards } from './services/websocket';
import GameTable from './components/GameTable';
import PlayerPosition from './components/PlayerPostion';
import PotDisplay from './components/PotDisplay';
import ActionButtons from './components/ActionButtons';
import PlayerInviteScreen from './components/PlayerInviteScreen';
import logo from '../../assets/images/black-logo.png';
import { FaChartBar } from 'react-icons/fa'; 
import  GameStatistics  from './components/GameStatistics'; 

function GamePage1() {
  const { game_id } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const { status, totalPlayers, myInfo, gameInfo } = useSelector((state) => state.game);
  const [progress, setProgress] = useState(0);
  const [showStats, setShowStats] = useState(false); // State to toggle game statistics modal
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check and event listener for resizing
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleConnect = async () => {
      try {
        for (let i = 0; i <= 100; i++) {
          setProgress(i);
          await new Promise((resolve) => setTimeout(resolve, 30));
        }
        await connectToGame(game_id, dispatch);
      } catch (error) {
        console.error('Failed to connect to game:', error);
      } finally {
        setLoading(false);
      }
    };

    handleConnect();
  }, [game_id, dispatch]);

  useEffect(() => {
    if (status === 'starting') {
      getPlayersCards(game_id);
    }
  }, [status, game_id]);

  if (loading) {
    return <LoadingScreen progress={progress} />;
  }

  if (isMobile) {
    return (
      <div className="flex items-center justify-center h-screen bg-green-500 text-white text-center p-4">
        <p>
          This game is best experienced on a larger screen. Please download our mobile app for a better experience.
        </p>
      </div>
    );
  }

  if (status === 'waiting' && totalPlayers === 1) {
    return <PlayerInviteScreen game_id={game_id} />;
  }

  const availablePlayers = [myInfo, ...(gameInfo?.players || [])];

  return (
    <div className="relative bg-green-500 h-screen flex items-center justify-center">
      <img
        src={logo}
        alt="Game Logo"
        className="absolute top-4 left-4 w-20 h-20 object-contain"
      />

      {/* Statistics Button */}
      <button
        onClick={() => setShowStats(!showStats)} // Toggle statistics modal
        className="absolute top-4 right-4 bg-green-700 p-2 rounded-full text-white hover:bg-green-800"
      >
        <FaChartBar size={24} /> {/* Icon for statistics */}
      </button>

      {/* Show Statistics Modal */}
      {showStats && (
        <GameStatistics
          gameId={game_id}  // Pass the game_id as a prop to GameStatistics
          onClose={() => setShowStats(false)}
        />
      )}
      {totalPlayers > 1 && (
        <GameTable>
          {availablePlayers.map((player, index) => {
            const playerId = player?.player_id || `player-${index}`;
            return <PlayerPosition key={playerId} player={player} />;
          })}
        </GameTable>
      )}

      <PotDisplay chips={[1, 2]} potValue={42} />
      <ActionButtons game_id={game_id} />
    </div>
  );
}

export default GamePage1;
