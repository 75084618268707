import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchUserProfile } from '../../../redux/features/auth/authSlice';
import logo from '../../../assets/images/black-logo.png';
import './AuthSucesspage.css';

const AuthSuccessPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // Get token from URL
        const query = new URLSearchParams(window.location.search);
        const token = query.get('token');
        localStorage.setItem('token',token);


        if (token) {
            // Fetch user profile using the token
            dispatch(fetchUserProfile(token)).then((action) => {
                if (action.type === 'auth/fetchProfile/fulfilled') {
                    // If profile fetch is successful, redirect to dashboard
                    navigate('/dashboard');
                } else {
                    // If there's an error, redirect to login or home page
                    navigate('/login');
                }
            });
        } else {
            // Case where token is not found
            navigate('/login');
        }
    }, [dispatch, navigate]);

    return (
        <div className="container">
            <img src={logo} alt="Logo" className="logo" />
            <h1>Authentication Successful</h1>
            <p>Redirecting...</p>
        </div>
    );
};

export default AuthSuccessPage;
