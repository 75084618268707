import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  gameInfo: {
    players: [],
  },
  currentRound: 0,
  totalPlayers: 0,
  status: 'waiting',
  blinds: {
    dealer: null,
    smallBlind: null,
    bigBlind: null,
  },
  myInfo: null,
  countdown: 0,
  playerCards: {},
  potValue: 0,
  communityCards: {
    flop_card1: null,
    flop_card2: null,
    flop_card3: null,
    turn_card: null,
    river_card: null,
  },
  nextPlayer: null,
  winner: null,
  messages: [],
  betPlaced: [],
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGameState(state, action) {
      state.gameInfo = {
        ...action.payload,
        players: Array.isArray(action.payload.players) ? action.payload.players : [],
      };
      state.totalPlayers = action.payload.current_players || 0;
      state.status = action.payload.status || 'waiting';
      state.currentRound = action.payload.current_round || 0;
      state.isLoading = false;
    },

    updatePlayersList(state, action) {
      if (Array.isArray(action.payload)) {
        state.gameInfo.players = action.payload;
      } else {
        console.error("Expected array of players but got:", action.payload);
      }
    },

    playerJoined(state, action) {
      if (action.payload && typeof action.payload === 'object') {
        state.gameInfo.players = [...state.gameInfo.players, action.payload];
        state.totalPlayers += 1;
      } else {
        console.error("Expected a player object but got:", action.payload);
      }
      state.status = 'waiting';
    },


    gameStarting(state, action) {
      const { dealer_position, small_blind, big_blind } = action.payload;
    
      // Reset player roles before assigning new ones
      state.gameInfo.players = state.gameInfo.players.map(player => ({
        ...player,
        playerRole: [], // Use an array to store multiple roles if needed
      }));
    
      try {
        // Assign roles to the correct players based on player_id
        state.gameInfo.players.forEach(player => {
          if (player.player_id === dealer_position) {
            player.playerRole.push('dealer'); // Add 'dealer' role
            state.blinds.dealer = player.player_id;
          }
          if (player.player_id === small_blind) {
            player.playerRole.push('small_blind'); // Add 'small_blind' role
            state.blinds.smallBlind = player.player_id;
          }
          if (player.player_id === big_blind) {
            player.playerRole.push('big_blind'); // Add 'big_blind' role
            state.blinds.bigBlind = player.player_id;
          }
        });
    
        // Check if myInfo corresponds to any of the roles
        if (state.myInfo) {
          state.myInfo.playerRole = []; // Initialize as an array to store multiple roles
          if (state.myInfo.player_id === dealer_position) {
            state.myInfo.playerRole.push('dealer');
          }
          if (state.myInfo.player_id === small_blind) {
            state.myInfo.playerRole.push('small_blind');
          }
          if (state.myInfo.player_id === big_blind) {
            state.myInfo.playerRole.push('big_blind');
          }
        }
    
        state.status = 'starting';
        state.countdown = 7; // Optional countdown logic
    
      } catch (error) {
        console.error('Error finding blinds:', error.message);
      }
    },    
    
    updateMyInfo(state, action) {
      state.myInfo = action.payload;
    },

    decrementCountdown(state) {
      if (state.countdown > 0) {
        state.countdown -= 1;
      }
    },

    updatePlayerCards(state, action) {
      const { potValue, playerCards } = action.payload;
      state.potValue = potValue;
      state.playerCards = playerCards;
      state.status = 'playing';
    },

    updateCommunityCards(state, action) {
      const { flop_card1, flop_card2, flop_card3, turn_card, river_card } = action.payload;

      if (flop_card1 !== undefined) {
        state.communityCards.flop_card1 = flop_card1;
      }
      if (flop_card2 !== undefined) {
        state.communityCards.flop_card2 = flop_card2;
      }
      if (flop_card3 !== undefined) {
        state.communityCards.flop_card3 = flop_card3;
      }
      if (turn_card !== undefined) {
        state.communityCards.turn_card = turn_card;
      }
      if (river_card !== undefined) {
        state.communityCards.river_card = river_card;
      }
    },

    setNextPlayer(state, action) {
      state.nextPlayer = action.payload;
    },

    updateAllPlayerCards(state, action) {
      const { player_cards, community_cards } = action.payload;
    
      // Update community cards if they exist
      if (community_cards) {
        state.communityCards = {
          flop_card1: community_cards[0] || null,
          flop_card2: community_cards[1] || null,
          flop_card3: community_cards[2] || null,
          turn_card: community_cards[3] || null,
          river_card: community_cards[4] || null,
        };
      }    
      // Iterate over the player_cards array
      player_cards.forEach(playerData => {
        // The playerData object has the player_id as the key
        Object.entries(playerData).forEach(([player_id, cards]) => {
    
          // Find the player in the current game state
          const player = state.gameInfo.players.find(p => p.player_id === player_id);
          if (player) {
            // Update player's cards
            player.card1 = cards[0] || null;
            player.card2 = cards[1] || null;
          } else {
            console.warn(`Player with ID ${player_id} not found in gameInfo.players. Current players:`, state.gameInfo.players);
          }
        });
      });
    
    },
    
    playerDisconnected(state, action) {
      const { player_id } = action.payload;
      state.gameInfo.players = state.gameInfo.players.filter(player => player.player_id !== player_id);
      state.totalPlayers -= 1;
      if (state.totalPlayers <= 1) {
        state.status = 'waiting';
      }
    },

    setWinner(state, action) {
      state.winner = action.payload.player_id; 
      state.status = 'winner_declared';
    },
    

    resetGameState(state) {
      state.status = 'round-completed';
      state.blinds = {
        dealer: null,
        smallBlind: null,
        bigBlind: null,
      };
      state.potValue = 0;
      state.playerCards = {};
      state.communityCards = {
        flop_card1: null,
        flop_card2: null,
        flop_card3: null,
        turn_card: null,
        river_card: null,
      };
      state.nextPlayer = null;
      state.winner = null;
      state.gameInfo.players = state.gameInfo.players.map(player => ({
        ...player,
        available_chips: 0,  
        card1: null,
        card2: null,
      }));    },

    updatePlayerBet(state, action) {
      const { player_id, available_chips, pot_value } = action.payload.details;

      const player = state.gameInfo.players.find(p => p.player_id === player_id);
      if (player) {
        player.available_chips = available_chips;
      } else if (state.myInfo && state.myInfo.player_id === player_id) {
        state.myInfo.available_chips = available_chips;
      }

      state.potValue = pot_value;
    },

    newBetUpdate(state, action) {
      const { player_id, amount, bet_type } = action.payload.details;
      const existingBet = state.betPlaced.find(bet => bet.player_id === player_id);
      if (existingBet) {
        state.betPlaced = [];
      }
      state.betPlaced.push({ player_id, amount, bet_type });
    },
    


    newMessageReceived(state, action) {
      const { player_id, message } = action.payload;
      state.messages.push({
        player_id,
        message,
        id: Date.now(),
      });
    },

    removeMessage(state, action) {
      state.messages = state.messages.filter(msg => msg.id !== action.payload);
    },



    updatePlayerChips(state, action) {
      const { player_id, new_chips, bet_amount } = action.payload; // Extract bet_amount from payload
      // First check if the player_id matches myInfo
      if (state.myInfo && state.myInfo.player_id === player_id) {
        state.myInfo.available_chips = new_chips;
      } else {
        // Find the player in gameInfo.players and update the available chips
        const player = state.gameInfo.players.find(p => p.player_id === player_id);
        if (player) {
          player.available_chips = new_chips;
        } else {
          console.error(`Player with id ${player_id} not found`);
        }
      }
      
      state.status = 'playing';
    
      // If a bet amount is provided, update the betPlaced array and the pot value
      if (bet_amount !== undefined) {
        const existingBet = state.betPlaced.find(bet => bet.player_id === player_id);
        if (existingBet) {
          // If the player already has a bet, we can update it or choose to keep the first one
          existingBet.amount += bet_amount; // Update existing bet amount
        } else {
          // If no existing bet, add a new entry
          state.betPlaced.push({ player_id, amount: bet_amount, bet_type: 'bet' }); // Adjust bet_type as needed
        }
    
        // Update the pot value by adding the bet amount
        state.potValue += bet_amount; // Update the pot
      }
    },
    
  },
});

// Export the actions
export const {
  updateGameState,
  updatePlayersList,
  playerJoined,
  updateMyInfo,
  gameStarting,
  decrementCountdown,
  updatePlayerCards,
  updateCommunityCards,
  setNextPlayer,
  setWinner,
  resetGameState, 
  updatePlayerBet,
  updatePlayerChips,
  newMessageReceived,
  removeMessage,
  newBetUpdate,
  playerDisconnected,
  updateAllPlayerCards,
} = gameSlice.actions;

export const handleSetWinner = (player_id) => (dispatch) => {
  dispatch(setWinner(player_id));
  setTimeout(() => {
    dispatch(resetGameState());
  }, 7000);
};


export default gameSlice.reducer;
