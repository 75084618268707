import React, { useState } from 'react';
import {  Link } from 'react-router-dom';
import googleLogo from '../assets/google.png';
import facebookLogo from '../assets/facebook.png';
import myLittleRiverLogo from '../../../assets/images/black-logo.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function Registration() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');
    setResponseStatus('');

    const username = e.target.username.value;
    const email = e.target.email.value;
    const password = e.target.password.value;
    const confirmPassword = e.target.confirm_password.value;

    // Check if passwords match
    if (password !== confirmPassword) {
      setResponseMessage("Passwords do not match.");
      setResponseStatus("error");
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // If success, display the success message and redirect to login
        setResponseMessage(data.message || "Registration successful!");
        setResponseStatus("success");
      } else {
        // If error, display the error message
        setResponseMessage(data.error || "Registration failed.");
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseMessage("An error occurred. Please try again.");
      setResponseStatus("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="w-full max-w-sm p-8">
        <div className="flex justify-center mb-8">
          <img src={myLittleRiverLogo} alt="My Little River Logo" className="h-32" />
        </div>

        <form onSubmit={handleRegister}>
          <div className="mb-4">
            <input
              type="text"
              id="username"
              name="username"
              className="mt-1 block w-full px-4 py-3 border border-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-[#009249]"
              placeholder="Enter username"
              required
            />
          </div>

          <div className="mb-4">
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 block w-full px-4 py-3 border border-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-[#009249]"
              placeholder="Enter email"
              required
            />
          </div>

          <div className="mb-4 relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              className="mt-1 block w-full px-4 py-3 border border-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-[#009249]"
              placeholder="Password"
              required
            />
            <div
              className="absolute inset-y-0 right-0 pr-4 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          <div className="mb-4 relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              className="mt-1 block w-full px-4 py-3 border border-gray-500 rounded-full focus:outline-none focus:ring-2 focus:ring-[#009249]"
              placeholder="Confirm Password"
              required
            />
            <div
              className="absolute inset-y-0 right-0 pr-4 flex items-center cursor-pointer"
              onClick={toggleConfirmPasswordVisibility}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>

          {/* Display the response message here */}
          {responseMessage && (
            <p className={`mb-4 text-center ${responseStatus === "success" ? "text-green-600" : "text-red-600"}`}>
              {responseMessage}
            </p>
          )}

          <button
            type="submit"
            className="w-full bg-[#009249] text-white py-3 px-4 rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-[#009249] relative"
            disabled={loading}
          >
            {loading ? (
              <div className="loader mx-auto border-4 border-t-4 border-white border-opacity-50 rounded-full w-6 h-6 animate-spin"></div>
            ) : (
              "Register"
            )}
          </button>
        </form>

        <div className="text-center mt-4">
          <p className="text-sm text-gray-600">
            Already have an Account?
            <Link 
              to="/login" 
              className="text-[#009249] hover:underline focus:outline-none"
              style={{ textDecoration: 'none' }}
            >
              Login
            </Link>

          </p>
        </div>


        <div className="flex items-center my-6">
          <hr className="flex-grow border-gray-400" style={{ height: '1px', backgroundColor: '#474042' }} />
          <span className="mx-4 text-gray-500">OR</span>
          <hr className="flex-grow border-gray-400" style={{ height: '1px', backgroundColor: '#474042' }} />
        </div>

        <div className="flex justify-center space-x-6">
          <a href="/auth/google">
            <img src={googleLogo} alt="Google" className="w-10 h-10" />
          </a>
          <a href="/auth/facebook">
            <img src={facebookLogo} alt="Facebook" className="w-10 h-10" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Registration;
