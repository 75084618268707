import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { placeBet } from '../services/websocket';
import decisionTicker from '../../../assets/sounds/decision_ticker.wav';

function ActionButtons({ game_id }) {
  const { status, nextPlayer, myInfo } = useSelector((state) => state.game);
  const [isRaise, setIsRaise] = useState(false); 
  const [raiseAmount, setRaiseAmount] = useState(0); 
  const [timeLeft, setTimeLeft] = useState(20);

  useEffect(() => {
    if (status !== 'playing' || !nextPlayer || nextPlayer.player_id !== myInfo.player_id) return;

    const timerInterval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev === 6) {
          // Play sound effect at 15-second mark
          new Audio(decisionTicker).play();
        }
        if (prev <= 1) {
          clearInterval(timerInterval);
          handleFoldAction(); // Automatically fold if timer expires
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [status, nextPlayer, myInfo]);

  const handleFoldAction = () => {
    placeBet(game_id, myInfo.player_id, 0, 'fold');
    setIsRaise(false);
    setTimeLeft(20); // Reset timer
  };

  const handleAction = (betType) => {
    const betAmount = betType === 'RAISE' ? raiseAmount : (betType === 'CALL' ? nextPlayer.bet_amount : 0);
    placeBet(game_id, myInfo.player_id, betAmount, betType.toLowerCase());
    setIsRaise(false);
    setTimeLeft(20); // Reset timer after action
  };

  const handleRaiseClick = () => {
    setIsRaise(true);
    setRaiseAmount(0);
  };

  // Only show buttons if game is in "playing" status and it’s the current player’s turn
  if (status !== 'playing' || !nextPlayer || nextPlayer.player_id !== myInfo.player_id) return null;

  return (
    <div className="absolute bottom-4 flex flex-col gap-3 sm:gap-4">
      <div className="flex gap-3">
        {['CALL', 'FOLD', 'RAISE'].map((action, index) => (
          <button
            key={index}
            onClick={() => action === 'RAISE' ? handleRaiseClick() : handleAction(action === 'CALL' && nextPlayer.bet_amount === '0' ? 'CHECK' : action)}
            className="bg-[#ccdec1] px-3 py-1 rounded-md shadow-md flex items-center border border-gray-300 hover:shadow-lg transition-shadow duration-300 sm:px-4 sm:py-1.5"
          >
            <div className="w-[0.70px] bg-white h-4 mr-1 sm:h-6"></div>
            <span className="text-[#1c1c1c] font-semibold text-xs sm:text-sm">
              {action === 'CALL' && nextPlayer.bet_amount === "0" ? 'CHECK' : action === 'CALL' ? `CALL ${nextPlayer.bet_amount}` : action}
            </span>
            <div className="ml-1">
              <span className="font-semibold text-xs sm:text-sm">▶</span>
            </div>
          </button>
        ))}
      </div>

      {/* Timer Display */}
      <span className="text-sm font-semibold text-[#1c1c1c] mt-1">
        Time Left: {timeLeft}s
      </span>

      {/* Slider for raising amount */}
      {isRaise && (
        <div className="flex flex-col items-center mt-3">
          <input
            type="range"
            min="0"
            max={myInfo.available_chips}
            value={raiseAmount}
            onChange={(e) => setRaiseAmount(Number(e.target.value))}
            className="w-full sm:w-3/4 h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer"
          />
          <span className="text-sm font-semibold text-[#1c1c1c] mt-1">
            Raise Amount: {raiseAmount} chips
          </span>
          <button
            onClick={() => handleAction('RAISE')}
            className="mt-2 bg-[#009249] text-white px-4 py-2 rounded-md shadow-md hover:bg-green-700 transition-colors duration-300"
          >
            Confirm Raise
          </button>
        </div>
      )}
    </div>
  );
}

export default ActionButtons;
