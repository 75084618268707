import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function MobileVerification() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [hasMobileNumber, setHasMobileNumber] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otp, setOtp] = useState(''); // Single input for OTP
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleMobileNumberSubmit = async () => {
    const token = localStorage.getItem('token');
    setIsSubmitting(true);
    try {
      const formattedPhoneNumber = `+${mobileNumber}`;
      const response = await fetch(`${apiBaseUrl}/api/mobile/add-phone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ phoneNumber: formattedPhoneNumber }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsSubmitting(false);
        setIsOtpModalOpen(true); // Open OTP verification modal
        setHasMobileNumber(true); // Update state to reflect that mobile number has been added
      } else {
        setIsSubmitting(false);
        setError(data.message || 'Failed to add mobile number. Please try again.');
        setIsModalOpen(true);
      }
    } catch (error) {
      setIsSubmitting(false);
      setError('Error adding mobile number. Please try again.');
      setIsModalOpen(true);
    }
  };

  const handleOtpInputChange = (event) => {
    const { value } = event.target;
    // Ensure only numbers are entered and limit it to 6 digits
    const validOtp = value.replace(/\D/g, '').slice(0, 6);
    setOtp(validOtp);
  };

  const handleOtpVerification = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`${apiBaseUrl}/api/mobile/verify-phone`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ code: otp }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsOtpModalOpen(false);
        setOtp(''); // Reset OTP
        setError('Mobile number verified successfully!');
        setIsModalOpen(true); // Show success message
      } else {
        setError(data.message || 'Invalid OTP. Please try again.');
        setIsModalOpen(true);
      }
    } catch (error) {
      setError('Error verifying OTP. Please try again.');
      setIsModalOpen(true);
    }
  };

  // Custom Modal Component using Tailwind CSS
  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white rounded-lg p-6 w-96">
          {children}
          <button
            className="mt-4 bg-red-600 text-white py-2 px-4 rounded w-full hover:bg-red-700"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* Mobile Number Section */}
      {!hasMobileNumber && (
        <div className="bg-red-600 text-white rounded-lg p-4 mb-6 text-center">
          <h2 className="text-2xl font-bold">Add Your Mobile Number!</h2>
          <p className="mb-4">To enhance your experience, please add your mobile number for verification.</p>
          <PhoneInput
            country={'us'}
            value={mobileNumber}
            onChange={setMobileNumber}
            placeholder="Enter mobile number"
            enableSearch={true} // Enable country name search
            searchPlaceholder="Search for a country"
            className="w-full mb-4 rounded-lg border border-black-300"
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            inputStyle={{
              paddingTop: 26,
              paddingLeft: 58,
              width: '100%',
              color:'black'
            }}
          />
          <button
            onClick={handleMobileNumberSubmit}
            disabled={isSubmitting}
            className={`bg-white text-green-700 font-bold py-2 px-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 ease-in-out w-full md:w-auto ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      )}

      {/* OTP Verification Modal */}
      <Modal isOpen={isOtpModalOpen} onClose={() => setIsOtpModalOpen(false)}>
        <div className="text-center p-6 bg-gray-100 rounded-lg">
          <h2 className="text-lg font-bold text-black mb-4">Enter the 6-digit OTP code</h2>
          <p className="mb-4 text-black">{`+${mobileNumber.slice(0, 3)} XXXXXX ${mobileNumber.slice(-3)}`}</p>

          <div className="mb-4">
            <input
              type="text"
              value={otp}
              onChange={handleOtpInputChange}
              maxLength={6}
              placeholder="Enter OTP"
              className="w-full text-center text-2xl border border-gray-300 rounded-md text-black p-2"
            />
          </div>

          <button
            onClick={handleOtpVerification}
            className="bg-blue-700 text-white py-2 px-6 rounded-lg font-bold w-full hover:bg-blue-800"
          >
            Verify
          </button>
        </div>
      </Modal>

      {/* Error Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-lg font-bold text-black">Error</h2>
        <p className="text-black">{error}</p>
      </Modal>
    </div>
  );
}

export default MobileVerification;
